import React from "react";

import {Card, CardBody, Image} from "@nextui-org/react"
import '../css/proyect.css'
import { motion } from "framer-motion";
const arrayData =[{
    path:'/assets/img/services/DISENO_ARQUITECTONICO.jpg',
    title:'Diseño Arquitectónico',
    description:'Diseñamos las bases de un proyecto a partir de bocetos, esquemas, geometrías, para poder crear un anteproyecto que nos pueda permitir tener un acercamiento al diseño final o bien realizar adecuaciones en base a las necesidades de cada cliente'
},{
    path:'/assets/img/services/PLANOS_ARQUITECTONICOS.jpg',
    title:'Planos Arquitectónicos',
    description:'Realizamos la representación gráfica de una futura obra para su construcción a base de planos como lo son cimentación, estructura, instalaciones entre otras, todo con la finalidad de ejecutar la obra.'
},{
    path:'/assets/img/services/MODELADO_3D.jpg',
    title:'Modelado 3D',
    description:'Creamos modelos de cualquier tipo de inmueble o objeto con la finalidad de transformar un concepto dándole volumen ,textura, color entre otras, con el propósito de hacerla perceptiva'
},{
    path:'/assets/img/services/RENDERIZADO.jpg',
    title:'Renderizado',
    description:'Ofrecemos la creación de imágenes 2D a partir de polígonos de modelos 3D,aplicando materiales, texturas, colores, iluminación de escenas entre otras cosas. El objetivo es mostrar un concepto, idea o proyecto de forma realista. Así como también recorrido virtuales a través de los espacios.'
}
];

const Architecture=() =>{
    
    return (
    <div className="" >
        <motion.div
  initial={{ opacity: 0 }}
  whileInView={{ opacity: 1 }}
  viewport={{ once: true }}
>
        <h1 className="p-8 text-5xl">ARQUITECTURA</h1></motion.div>
        <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-6 justify-items-center px-5">

        {arrayData.map(x=>(
            <Card
      isBlurred
      className="transparent-backgroundLabel border-none  bg-slate-10 shadow-md shadow-slate-10 px-2"
      shadow="sm"
    >
      <CardBody >
        <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
          <div className="relative col-span-6 md:col-span-4">
            <Image isZoomed
              alt="Album cover"
              className="object-cover"
              height={300}
              shadow="md"
              src={x.path}
              width="100%"
            />
          </div>

          <div className="flex flex-col col-span-6 md:col-span-8">
            <div className="flex justify-between ">
              <div className="flex flex-col gap-0">
              <h3 className="font-semibold text-white md:text-5xl sm:text-sm">{x.title}</h3>
              <h2 className=" mt-8 text-justify md:text-3xl sm:text-sm">{x.description}</h2>
              </div>
             
            </div>
          </div>
        </div>
      </CardBody>
    </Card>      
))}

        </div>
    </div>
    )
}

export default Architecture;