
import './App.css';
import MyHeader from './component/Header';
// Supports weights 100-900
import '@fontsource-variable/montserrat';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import About from './pages/about';
import Architecture from './pages/architecture';
import Gallery from './pages/gallery';
import Ingeniery from './pages/ingeniery';
import Service from './pages/service';
import Contact from './pages/contact';
import ProyectArchitecture from './pages/proyectA';
import ProyectStructure from './pages/proyectS';

function App() {
  return (
    
    
    <div className="App">   
      <BrowserRouter>
      <MyHeader />
        <Routes>
        <Route path="/" element={<Home/>}></Route>
          <Route path="/home" element={<Home/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/architecture" element={<Architecture/>}></Route>
          <Route path="/ingeniery" element={<Ingeniery/>}></Route>
          <Route path="/proyect" element={<ProyectStructure/>}></Route>
          <Route path="/service" element={<Service/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path="/gallery" element={<Gallery/>}></Route>

          <Route path="/proyectA" element={<ProyectArchitecture/>}></Route>
          <Route path="/proyectS" element={<ProyectStructure/>}></Route>
        </Routes>  
      </BrowserRouter>

    </div>
    
  );
}

export default App;

