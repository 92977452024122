import React, {useEffect,useRef, useState} from "react";
import {Card, CardHeader} from "@nextui-org/react";

import '../css/home.css';

const arrayImg =[{
    path:"/assets/img/home/FACHADA-PRINCIPAL.jpg",
    titulo:"FACHADA PRINCIPAL",
    descripcion:"FACHADA DE BODEGAS INDUSTRIALES"
},
{
    path:"/assets/img/home/PATIO-DE-MANIOBRAS.jpg",
    titulo:"PATIO DE MANIOBRAS",
    descripcion:"PATIO DE BODEGA INDUSTRIAL"
},
{
    path:"/assets/img/home/RAMPA-NIVELADORA.jpg",
    titulo:"RAMPA NIVELADORA",
    descripcion:"RAMPA DE BODEGAS INDUSTRIALES"
},
{
    path:"/assets/img/home/INTERIOR-DE-BODEGAS.jpg",
    titulo:"INTERIOR BODEGA",
    descripcion:"ESPACIO Y COMODIDAD "
}];

const Home=() =>{
    const [currentState,setCurrentState]= useState(0);
    
    useEffect(()=>{
        const timer = setTimeout(()=>{
            if(currentState===(arrayImg.length-1)){
                setCurrentState(0)
            }else{
                setCurrentState(currentState+1)
            }            
        },5000)
        return ()=> clearTimeout(timer)
    },[currentState])
    const bgImageStyle ={
        backgroundImage: `url(${arrayImg[currentState].path})`,
        backgroundPosition:'center',
        backgroundSize:'cover',
        height:'100%'
    } 
    const goToNext = (currentState)=>{
        setCurrentState(currentState)
    }

    return (
        <div className="countiner-style ">

            <div style={bgImageStyle}></div>
            <div className="transparent-background"></div>
            <div className="description" tabindex="0">
                

    <Card className="max-w-[280px] md:max-w-lg transparent-backgroundCard flex justify-center ">
      <CardHeader className="flex justify-center ">
          <div className="flex flex-col justify-center z-0">            
          <h1 className="text-4xl font-medium mt-2 z-0 flex justify-center text-white">{arrayImg[currentState].titulo}</h1>
            <h4 className="text-lg font-semibold leading-none text-default-500 z-0 text-white">{arrayImg[currentState].descripcion}</h4>
          </div><br></br>
          
      </CardHeader>
      <div className="carousel-boult flex justify-center pb-1">
                    {
                        arrayImg.map((arrayImg,currentState)=>(
                            <span key={currentState} onClick={()=> goToNext(currentState)}></span>
                        ))
                    }
                </div>
    </Card>

            </div>
        </div>
    );
}

export default Home;