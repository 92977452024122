import React from "react";


import {Card, CardBody, Image} from "@nextui-org/react"
import '../css/proyect.css'
const arrayData =[{
    path:'/assets/img/services/CALCULO_ESTRUCTURAL.jpg',
    title:'Cálculo estructural',
    description:'Realizamos cálculos para medir los efectos de las cargas, tanto de fuerzas internas como externas que pueden incidir en la construcción de una estructura, de esta manera se garantiza la seguridad y estabilidad de los inmuebles'
},{
    path:'/assets/img/services/INGENIERIA_BASICA.jpg',
    title:'Ingeniería básica',
    description:'Definimos los lineamentos generales de los proyectos que fungen como pilares de la ingeniería a detalle para lograr la ejecución de los planos constructivos'
},{
    path:'/assets/img/services/INGENIERIA_DETALLE.jpg',
    title:'Ingeniería a Detalle',
    description:'Detallamos todos los valores y especificaciones de la ingeniería básica para poder crear minuciosamente cada elemento correspondiente a la estructura desde el concepto hasta la fabricación'
},{
    path:'/assets/img/services/MODELADO_ESTRUCTURAL.jpg',
    title:'Modelado Estructural',
    description:'Representamos modelos 3d con ayuda de un software para mostrar la información de la construcción ,capaz de modelar estructuras que integren diferentes tipos de materiales como acero, concreto.'
}
];
const Ingeniery=() =>{
    const [liked, setLiked] = React.useState(false);
    return (
    <div className="" >
        <h1  className="p-8 text-5xl ">INGENIERÍA</h1>
        <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-6 justify-items-center px-5">

        {arrayData.map(x=>(
            <Card
      isBlurred
      className="transparent-backgroundLabel border-none  bg-slate-10 shadow-md shadow-slate-10 px-2"
      shadow="sm"
    >
      <CardBody >
        <div className="grid grid-cols-6 md:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
          <div className="relative col-span-6 md:col-span-4">
            <Image isZoomed
              alt="Album cover"
              className="object-cover"
              width={500}
              height={500}
              src={x.path}
            />
          </div>

          <div className="flex flex-col col-span-6 md:col-span-8">
            <div className="flex justify-between ">
              <div className="flex flex-col gap-0">
                <h3 className="font-semibold text-white md:text-5xl sm:text-sm">{x.title}</h3>
                <h2 className=" mt-8 text-justify md:text-3xl sm:text-sm">{x.description}</h2>
              </div>
             
            </div>
          </div>
        </div>
      </CardBody>
    </Card>      
))}

        </div>
    </div>
    )
}

export default Ingeniery;