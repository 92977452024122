import React from "react";
import {Image} from "@nextui-org/react";

import face from '../img/contact/facebookqr.PNG';
import insta from '../img/contact/instagramqr.PNG';
import tiktok from '../img/contact/tiktokqr.PNG';
import { IoMdPhonePortrait } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { FaClock } from "react-icons/fa";


const Contact=() =>{
    return (<div ClassName="w-full h-full"> 
    <div className="w-full h-48 bg-slate-100 text-black ">
  <h1 className="p-5 text-5xl">CONTÁCTENOS</h1>
  <p className="text-center py-5 ">Ponemos a su disposicion los siguientes medios de contacto, no dude en hacernos saber en que podemos ayudarte.</p>
  </div>
  <div className="w-full grid grid-rows-1 md:grid-cols-4 sm:grid-cols-2 gap-4   items-center justify-center mt-5 mb-5">    
      <div className="text-center content-center items-center mt-5 mb-5"> 
              <div className="grid grid-rows-1 items-center justify-center "><IoMdPhonePortrait className="size-16 mb-3"/></div>

              <div  className="grid grid-rows-1 items-center justify-center"><b>TELEFONOS</b></div>
              <div className="grid grid-rows-1 items-center justify-center">
                <p className="text-default-500"><a href="tel:4613249735">461 324 9735</a></p>
                <p className="text-default-500"><a href="tel:4291236271">429 123 6271</a></p></div>
           
      </div>
      <div className="text-center content-center items-center mt-5 mb-5">
        <div className="grid grid-rows-1 items-center justify-center"><FaLocationDot className="size-16 mb-3" /> </div> 

<div  className="grid grid-rows-1 items-center justify-center"><b>DIRECCIÓN</b></div>
<div className="grid grid-rows-1 items-center justify-center"><p className="text-default-500">Celaya, Gto.</p></div>
      </div>
      <div className="text-center content-center items-center mt-5 mb-5">
      <div className="grid grid-rows-1 items-center justify-center"><IoIosMail className="size-16 mb-3"/></div> 

<div  className="grid grid-rows-1 items-center justify-center"><b>EMAIL</b></div>
<div className="grid grid-rows-1 items-center justify-center">
  <p className="text-default-500"><a href="mailto:arquitectura@3design.com.mx">arquitectura@3design.com.mx</a> </p>
  <p className="text-default-500"><a href="mailto:ingenieria@3design.com.mx">ingenieria@3design.com.mx</a></p></div>
        
        </div>
      <div className="text-center content-center items-center mt-5 mb-5"> 
      <div className="grid grid-rows-1 items-center justify-center"><FaClock className="size-16 mb-3"/></div> 

<div  className="grid grid-rows-1 items-center justify-center"><b>HORARIO DE SERVICIO</b></div>
<div className="grid grid-rows-1 items-center justify-center"><p className="text-default-500">L-V 9:00AM a 5:00PM</p></div>
     

      </div>
  </div>

  <div className="w-full grid grid-rows-1 grid-cols-3 gap-4 flex content-center " >
  <div className="flex  justify-center"><Image
      width={200}
      height={200}
      alt="facebook"
      src={face}
      className="mt-5 mb-5 "
    /></div>
      <div className="flex  justify-center">  <Image
      width={200}
      height={200}
      alt="instagram"
      src={insta}
      className="mt-5 mb-5"
    /></div>
      <div className="flex  justify-center"> <Image
      width={200}
      height={200}
      alt="tiktok"
      src={tiktok}
      className="mt-5 mb-5"
    /></div>
  </div>


  </div>
)}

export default Contact;