import React from "react";
import { Link } from "react-router-dom";
import {Divider, Spacer,Card, Image,CardBody} from "@nextui-org/react";

import '../css/proyect.css'
const arrayData =[
    {
        path:'/assets/img/proyects/architecture/RENDER-FACHADA.jpg',
        title:'RENDER DE FACHADAS',
        subtitle:'Diseño de fachada, de nave industrial detalles de lámina R-101 y accesos',
        items:[],
        weight:'1000 m2'
    },
    
{
    path:'/assets/img/proyects/architecture/CASA-TORRES.jpg',
    title:'CASA TORRES',
    subtitle:'Diseño Aarquitectónico estilo moderno. Espacios amplios y ventilados',
    items:['Sala',
            'Comedor',
            'Cocina',
            'Dos baños',
            '4 recamaras',
            'Terraza',
            'Cochera'],
    weight:'130 m2'
},
{
    path:'/assets/img/proyects/architecture/PROYECTO-BERNANRDO.jpg',
    title:'CASA BERNARDO',
    subtitle:'Diseño y remodelación de primer nivel',
    items:['Sala',
            'Cocina',
            'Baño completo',
            '2 recamaras',
            '1 Recamara Master con baño'],
    weight:'120 m2'
},
{
    path:'/assets/img/proyects/architecture/PROYECTO-LE.jpg',
    title:'PROYECTO LE',
    subtitle:'Diseño Aarquitectónico estilo moderno. Espacios amplios, ventilados e iluminados',
    items:['Sala',
            'Comedor',
            'Cocina',
            'Dos baños',
            '3 recamaras'],
    weight:'100 m2'
},
{
    path:'/assets/img/proyects/architecture/CASA_MORELIA.jpg',
    title:'CASA MORELIA',
    subtitle:'Diseño arquitectónico estilo contemporáneo',
    items:['Sala',
            'Comedor',
            'Cocina', 
            'Dos baños',
            '4 recamaras'],
    weight:'80 m2'
},
{
    path:'/assets/img/proyects/architecture/PROYECTO-DANIEL.jpg',
    title:'PROYECTO CASA DANIEL',
    subtitle:'Diseño de sala, comedor y cocina, utilizando iluminación artificial y natural para dar ese toque de calidez y comodidad.',
    items:[],
    weight:'42 m2'
},
{
    path:'/assets/img/proyects/architecture/COFFE-IRAPUATO.jpg',
    title:'THE ITALIAN COFFEE',
    subtitle:'Diseño The Italian Coffee',
    items:['Área de baños',
        'Área de terraza',
        'Área de preparación de alimentos'
    ],
    weight:'42 m2'
},
{
    path:'/assets/img/proyects/architecture/ITALIAN-COFFE.jpg',
    title:'THE ITALIAN COFFEE SORIANA',
    subtitle:'Remodelación interior de The italian coffee, uso de iluminación artificial, vegetación artificial, remodelación en base a catálogo de materiales.',
    items:[],
    weight:'30 m2'
},
{
    path:'/assets/img/proyects/architecture/GYM-SANLUIS.jpg',
    title:'GYM SAN LUIS',
    subtitle:'',
    items:[''],
    weight:''
},
{
    path:'/assets/img/proyects/architecture/TECHO-COCHERA.jpg',
    title:'TECHO COCHERA',
    subtitle:'',
    items:[''],
    weight:''
}
];

const links =[{
    name:"ESTRUCTURA",
    href:"/proyectS"
},{
    name:"ARQUITECTURA",
    href:"/proyectA"
}];
/*var secuencia = [];
function generarSecuencia(tam) {
    for (var n = 1; n <= tam; n++) {  // Generamos los números del 1 al 6
        if (n % 2 === 1) {  // Si n es impar
            secuencia.push(2 * n - 1);
        } else {  // Si n es par
            secuencia.push(2 * n);
        }
    }
    return secuencia;
}*/
const ProyectArchitecture=() =>{
    return (<div ClassName="w-full "> 
        <h1 className="p-5 text-5xl">PROYECTOS</h1>
      <div className=" flex justify-center ">
          {links.map(x=>(
                  <Link className="p-5" to={x.href} aria-current="page" color="secondary" >
                      <label className="transparent-backgroundLabel">{x.name}</label>  
                  </Link> 
      ))}
      <Spacer x={4}/>
      </div>
      <Divider className="my-4" />
    
      
      <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4 ml-6 mr-6  flex justify-center">
      {arrayData.map((x,index)=>(
      <Card
            isBlurred
            className="transparent-backgroundLabel"
          > 
            <CardBody className="">
              <div className="grid grid-cols-12 md:grid-cols-12 gap-1 md:gap-1 flex justify-center">
                <div className="flex justify-center col-span-12 md:col-span-12">
                <Image
                    alt="Album cover"
                    className="object-cover"
                    shadow="md"
                    src={x.path}
                    />
                </div>
      
                <div className="flex justify-center flex-col col-span-12 md:col-span-12">
                  <div className="flex justify-between  items-start">
                    <div className="flex flex-col justify-center col-span-12 gap-0">
                    <h1 className=" text-large font-medium mt-2 ">{x.title} - {x.weight} - {((index%2)==0 ? index+1:index+1)}</h1>
                      <h3 className="font-semibold text-white ">{x.subtitle}  </h3>
                    </div>
                   
                  </div>
                   
                  {x.items.length>0 ? (
                      <div className="w-full    rounded-small">
                 
                      {x.items.map((texto)=>
                       <div className="flex  ">
                       <li>{texto}</li>
                          
                     </div>
                     )}
                         </div>
                  ) : (
                     <div></div>
                  )} 
                  
                </div>
              </div>
            </CardBody>
          </Card>
      ))}
      </div>
      
          </div>)
    
    
}

export default ProyectArchitecture;