import React from "react";
import {Card, CardBody, Image} from "@nextui-org/react";
import {Modal, ModalContent, ModalBody, useDisclosure} from "@nextui-org/react";


import '../css/gallery.css'
const Gallery=() =>{
  const {isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const [size, setSize] = React.useState('md')
  const [selectedImage, setSelectedImage] = React.useState('md')


  const handleOpen = (size,imgsrc) => {
    setSize(size)
    setSelectedImage(imgsrc);
    onOpen();
  }

  const list = [
    {
      title: "Lemon 2",
      img: "/assets/img/gallery/64.JPG",
      price: "$8.00",
    },
    {
      title: "Banana",
      img: "/assets/img/gallery/casa1.JPG",
      price: "$7.50",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/casa2.JPG",
      price: "$12.20",
    },{
      title: "Orange",
      img: "/assets/img/gallery/casa3.JPG",
      price: "$5.50",
    },
    {
      title: "Tangerine",
      img: "/assets/img/gallery/casa4.JPG",
      price: "$3.00",
    },
    {
      title: "Raspberry",
      img: "/assets/img/gallery/casa5.JPG",
      price: "$10.00",
    },
    {
      title: "Lemon",
      img: "/assets/img/gallery/casa6.JPG",
      price: "$5.30",
    },
    {
      title: "Avocado",
      img: "/assets/img/gallery/casa8.JPG",
      price: "$15.70",
    },
    {
      title: "Lemon 2",
      img: "/assets/img/gallery/cocina1.JPG",
      price: "$8.00",
    },
    {
      title: "Banana",
      img: "/assets/img/gallery/cocina2.JPG",
      price: "$7.50",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/cocina3.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/coffe1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/coffe2.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/comedor1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/crepa1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/escaleras1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/estructura1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/gym1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/gym2.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/inova1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/inova2.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/inova3.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/nave1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/nave2.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/office1.JPG",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/sala1.JPG",
      price: "$12.20",
    }
  ];

    return (
      <>
      <div class="w-full px-0">
        <h1 className="p-5 text-5xl uppercase">Galeria</h1>

        
        
        <div className="gap-0 p-0 grid md:grid-cols-8 sm:grid-cols-4 grid-cols-5 ">
      {list.map((item, index) => (
        <Card shadow="sm" radius="none" key={index} isPressable /*key={size}*/ 
                                  onPress={() => handleOpen("full",item.img)}>
          <CardBody className="overflow-visible p-0" >
            <Image
              shadow="sm"
              radius="none"
              width="100%"
              alt={item.title}
              className="w-full  h-[140px]"
              src={item.img}
            />
          </CardBody>
        </Card>
      ))}
    </div>


    </div>
    {selectedImage && (
    <Modal 
       size={size} 
       isOpen={isOpen} 
       onClose={onClose} 
       onOpenChange={onOpenChange}
       className="bg-transparent"
      >
    <ModalContent>

          {(onClose) => (
            <>
              <ModalBody className="flex justify-center items-center w-full bg-transparent">
              <Image
              shadow="sm"
              radius="none"
              className="w-full h-full"
              src={selectedImage}
              onClick={onClose}
            />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      )}
    </>
  );
}

export default Gallery;